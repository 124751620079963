import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import DefaultPageHeaderButton from 'components/buttons/DefaultPageHeaderButton';
import { logout as logoutAction } from 'redux/modules/auth/actions';
import trackEvent from 'redux/modules/tracking/actions';
import { EventType } from 'redux/modules/tracking/types';

interface Props {
  isPageHeaderExtra?: boolean;
}

function LogOutButton({ isPageHeaderExtra }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const handleClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(
      trackEvent({
        type: EventType.LOG_OUT,
        data: { status: true, buttonLocation: 'Settings Page' }
      })
    );
    dispatch(logoutAction());
  };

  if (isPageHeaderExtra) {
    return (
      <DefaultPageHeaderButton onClick={handleClick}>
        LOG OUT
      </DefaultPageHeaderButton>
    );
  }

  return (
    <Button type="primary" onClick={handleClick}>
      LOG OUT
    </Button>
  );
}

export default LogOutButton;
