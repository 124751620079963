import { Button } from 'antd';
import React, { ReactNode, memo } from 'react';
import { useSelector } from 'react-redux';

import DefaultDrawer from 'components/composites/DefaultDrawer';
import { makeSelectIsLoading } from 'redux/modules/loading/selectors';

interface Props {
  visible: boolean;
  actions: string[];
  backOfficeSection: string;
  handleClick?: () => void;
  handleClose: () => void;
  noFooter?: boolean;
  footerButton?: ReactNode;
  children: ReactNode;
}

/**
 * Template component for drawers in the Back Office.
 */
function BackOfficeDrawer({
  visible,
  actions,
  backOfficeSection,
  handleClick,
  handleClose,
  noFooter,
  footerButton,
  children
}: Props): JSX.Element {
  const selectIsLoading = makeSelectIsLoading(actions);
  const isLoading = useSelector(selectIsLoading);
  const footer = noFooter ? null : (
    <div style={{ padding: '0 10%' }}>
      {footerButton || (
        <Button
          block
          size="large"
          onClick={handleClick}
          type="primary"
          loading={isLoading}
        >
          SAVE
        </Button>
      )}
    </div>
  );

  return (
    <DefaultDrawer
      id={backOfficeSection}
      placement="right"
      width={400}
      mask={false}
      disableAnimation
      title={backOfficeSection}
      visible={visible}
      onClose={handleClose}
      footer={footer}
    >
      {children}
    </DefaultDrawer>
  );
}

export default memo(BackOfficeDrawer);
