/**
 * Takes an array of concatenated longitude and latitude values and calculates
 * bounds.
 * Used for making sure all markets fit on a map.
 */

import { NESWBounds, fitBounds } from 'google-map-react';

import { LatLong } from 'models/asset';
import Country from 'models/country';

import { getSafeBounds } from './get-safe-bounds';

const defaultZoom = 5;
const maxZoom = 20;
const minZoom = 1;

/**
 * latLngFitBounds
 * @param latLongs
 * @param width
 * @param height
 * @param country To provide default coordinates
 */
export function latLngFitBounds(
  latLongs: LatLong[],
  width: number,
  height: number,
  country: Country
) {
  if (latLongs.length === 1) {
    const [lat, lng] = latLongs[0];

    return { zoom: 17, center: { lat, lng } };
  }

  const bounds = latLongs.reduce<NESWBounds | null>(
    (accumulator, currentValue) => {
      const [lat, lng] = currentValue;

      if (!accumulator) {
        return {
          ne: {
            lat,
            lng
          },
          sw: {
            lat,
            lng
          }
        };
      }

      if (lat > accumulator.ne.lat) {
        accumulator.ne.lat = lat;
      }
      if (lng > accumulator.ne.lng) {
        accumulator.ne.lng = lng;
      }
      if (lat < accumulator.sw.lat) {
        accumulator.sw.lat = lat;
      }
      if (lng < accumulator.sw.lng) {
        accumulator.sw.lng = lng;
      }
      return accumulator;
    },
    null
  );

  // Default to the center of the country
  if (!bounds) {
    return {
      center: { lat: country.lat, lng: country.long },
      zoom: defaultZoom
    };
  }

  // Allow for the height of the marker so that they aren't cut off
  const markerOffset = 100;

  const size = {
    width,
    height: height - markerOffset
  };

  // fitBounds breaks if ne/sw's lat or lng is equal
  // https://github.com/google-map-react/google-map-react/issues/207#issuecomment-400579621
  const safeBounds = getSafeBounds(bounds);

  const result = fitBounds(safeBounds, size);

  const lowerBoundZoom = Math.min(
    Number.isNaN(result.zoom) ? defaultZoom : result.zoom,
    maxZoom
  );

  const zoom = Math.max(lowerBoundZoom, minZoom);

  return {
    ...result,
    zoom
  };
}
