import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { DeviceNotificationPolicy, ExternalDeviceNotificationPolicy } from 'models/notificationPolicy';
import { SiteId } from 'models/site';

import ActionTypes from './constants';

export const fetchNotificationPolicies = (siteId: SiteId) =>
  action(ActionTypes.FETCH_NOTIFICATION_POLICIES_REQUEST, { siteId });

export const fetchNotificationPoliciesSuccess = () =>
  action(ActionTypes.FETCH_NOTIFICATION_POLICIES_SUCCESS);

export const fetchNotificationPoliciesFailure = (message: string) =>
  action(ActionTypes.FETCH_NOTIFICATION_POLICIES_FAILURE, { message }, undefined, true);

export const fetchExternalDeviceNotificationPolicies = (siteId: SiteId) =>
  action(ActionTypes.FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST, { siteId });

export const fetchExternalDeviceNotificationPoliciesSuccess = () =>
  action(ActionTypes.FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS);

export const fetchExternalDeviceNotificationPoliciesFailure = (message: string) =>
  action(ActionTypes.FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE, { message }, undefined, true);

export const editNotificationPolicies = (siteId: SiteId, deviceNotificationPolicies: DeviceNotificationPolicy[]) =>
  action(ActionTypes.EDIT_NOTIFICATION_POLICIES_REQUEST, { siteId, deviceNotificationPolicies });

export const editNotificationPoliciesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_NOTIFICATION_POLICIES_SUCCESS, { response });

export const editNotificationPoliciesFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_NOTIFICATION_POLICIES_FAILURE, { message, error }, undefined, true);

export const editExternalDeviceNotificationPolicies = (
  siteId: SiteId,
  notificationPolicies: ExternalDeviceNotificationPolicy[]
) =>
  action(ActionTypes.EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST, { siteId, notificationPolicies });

export const editExternalDeviceNotificationPoliciesSuccess = () =>
  action(ActionTypes.EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS);

export const editExternalDeviceNotificationPoliciesFailure = (message: string) =>
  action(ActionTypes.EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE, { message }, undefined, true);

export const setNotificationPolicies = (siteId: number, data: DeviceNotificationPolicy[]) =>
  action(ActionTypes.SET_NOTIFICATION_POLICIES, { siteId, data });

export const setExternalDeviceNotificationPolicies = (siteId: number, data: ExternalDeviceNotificationPolicy[]) =>
  action(ActionTypes.SET_EXTERNAL_DEVICE_NOTIFICATION_POLICIES, { siteId, data });
