import { useDispatch, useSelector } from 'react-redux';

import { EnterpriseId } from 'models/enterprise';
import { ApplicationRootState } from 'redux/types';

import { closeBackOfficeCiboCancellationModal, closeBackOfficeCiboPlacedZonesModal, openBackOfficeCiboCancellationModal } from './actions';

export const useCiboSubscriptions = (enterpriseId: EnterpriseId) =>
  useSelector((state: ApplicationRootState) => state.backOfficeCiboSubscriptions.subscriptions[enterpriseId] || []);

export const useCiboLocations = (enterpriseId: EnterpriseId) =>
  useSelector((state: ApplicationRootState) => state.backOfficeCiboSubscriptions.locations[enterpriseId] || []);

export const useCiboCancellationModal = (subscriptionId: number) => {
  const dispatch = useDispatch();

  const modalState = useSelector((state: ApplicationRootState) => state.backOfficeCiboSubscriptions.cancellationModal);
  if (modalState?.subscriptionId !== subscriptionId) {
    return {
      isOpen: false,
      openModal: () => dispatch(openBackOfficeCiboCancellationModal(subscriptionId))
    };
  }
  return {
    isOpen: true,
    closeModal: () => dispatch(closeBackOfficeCiboCancellationModal(subscriptionId))
  };
};

export const useCiboPlacedZonesModal = (subscriptionId: number) => {
  const dispatch = useDispatch();

  const modalState = useSelector((state: ApplicationRootState) => state.backOfficeCiboSubscriptions.placedZonesModal);
  if (modalState?.subscriptionId !== subscriptionId) {
    return {
      isOpen: false
    };
  }
  return {
    isOpen: true,
    numberOfLocationsToRemove: modalState.numberOfLocationsToRemove,
    closeModal: () => dispatch(closeBackOfficeCiboPlacedZonesModal(subscriptionId))
  };
};