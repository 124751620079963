import { action } from 'typesafe-actions';

import { EnterpriseId } from 'models/enterprise';
import Notification, { NotificationId } from 'models/notification';

import ActionTypes from './constants';

export const loadRecentNotifications = (enterpriseId: EnterpriseId, isInitialLoading?: boolean) =>
  action(ActionTypes.LOAD_RECENT_NOTIFICATIONS_REQUEST, { enterpriseId, isInitialLoading });

export const loadRecentNotificationsSuccess = () => action(ActionTypes.LOAD_RECENT_NOTIFICATIONS_SUCCESS);

export const loadRecentNotificationsFailure = (message: string) =>
  action(ActionTypes.LOAD_RECENT_NOTIFICATIONS_FAILURE, { message }, undefined, true);

export const acknowledgeRecentNotification = (notificationId: NotificationId) =>
  action(ActionTypes.SET_PENDING_ACKNOWLEDGEMENT, { notificationId });

export const removePendingAcknowledgement = (notificationId: NotificationId) =>
  action(ActionTypes.REMOVE_PENDING_ACKNOWLEDGEMENT, { notificationId });

export const acknowledgeRecentNotificationSuccess = () => action(ActionTypes.ACKNOWLEDGE_RECENT_NOTIFICATION_SUCCESS);

export const acknowledgeRecentNotificationFailure = (message: string) =>
  action(ActionTypes.ACKNOWLEDGE_RECENT_NOTIFICATION_FAILURE, { message }, undefined, true);

export const setRecentNotifications = (
  enterpriseId: EnterpriseId,
  recentNotifications: Record<NotificationId, Notification>
) =>
  action(ActionTypes.SET_RECENT_NOTIFICATIONS, { enterpriseId, recentNotifications });

export const setRecentNotification = (notification: Omit<Notification, 'event'>) =>
  action(ActionTypes.SET_RECENT_NOTIFICATION, notification);
