import React from 'react';

import DefaultButton from 'components/atoms/DefaultButton';
import DefaultModal from 'components/atoms/DefaultModal';
import NewTabLink from 'components/atoms/NewTabLink';
import { useCiboCancellationModal, useCiboPlacedZonesModal } from 'redux/modules/backOfficeCiboSubscriptions/hooks';

interface CancelSubscriptionButtonProps {
  disabled?: boolean;
  enterpriseDomain: string;
  dateAdded: string;
  subscriptionId: number;
  onCancel: (subscriptionId: number) => void;
  isCancelling: boolean;
}

function CancelSubscriptionButton({
  disabled,
  enterpriseDomain,
  dateAdded,
  subscriptionId,
  onCancel,
  isCancelling
}: CancelSubscriptionButtonProps) {
  const {
    isOpen: isConfirmModalOpen,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal
  } = useCiboCancellationModal(subscriptionId);
  const {
    isOpen: isRecheckModalOpen,
    closeModal: closeRecheckModal,
    numberOfLocationsToRemove
  } = useCiboPlacedZonesModal(subscriptionId);

  return (
    <>
      <DefaultButton onClick={openConfirmModal} disabled={disabled}>
        Cancel Subscription
      </DefaultButton>
      <DefaultModal
        title="Check Placed Pasture Zones"
        isOpen={isRecheckModalOpen}
        dismissText="Go Back"
        onDismiss={closeRecheckModal}
        confirmText={
          <NewTabLink
            href={`${enterpriseDomain}/enterprise/sample-points/map-view`}
            text="Go to Enterprise"
          />
        }
        // The user is checking the placed pasture zones in another tab anyway, so we can just close the modal
        onConfirm={closeRecheckModal}
        width={360}
      >
        Before cancelling, please remove <strong>{numberOfLocationsToRemove}</strong> Pasture Zone(s) from the map
        to match the remaining Total Purchased (the amount after cancelling).
        <br />Please update billing to reflect new amount.
      </DefaultModal>
      <DefaultModal
        title="Cancel Subscription?"
        isOpen={isConfirmModalOpen}
        dismissText="Go Back"
        onDismiss={closeConfirmModal}
        confirmText="Cancel Subscription"
        isConfirmButtonDangerous
        onConfirm={() => onCancel(subscriptionId)}
        isConfirmButtonLoading={isCancelling}
        width={360}
      >
        Are you sure you want to cancel this item<br /> that was added on {dateAdded}?
        <br />This action cannot be undone.
      </DefaultModal>
    </>
  );
}

export default CancelSubscriptionButton;