import { ApplicationActions } from 'redux/types';

import ActionTypes from './constants';
import { BackOfficeCiboSubscriptionsState } from './types';

export const initialState: BackOfficeCiboSubscriptionsState = {
  subscriptions: {},
  locations: {},
  cancellationModal: null,
  placedZonesModal: null
};

const backOfficeCiboSubscriptionsReducer = (
  state: BackOfficeCiboSubscriptionsState = initialState,
  action: ApplicationActions
): BackOfficeCiboSubscriptionsState => {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_CIBO_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.enterpriseId]: action.payload.subscriptions
        }
      };
    case ActionTypes.SET_BACK_OFFICE_CIBO_LOCATIONS:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.enterpriseId]: action.payload.locations
        }
      };
    case ActionTypes.OPEN_BACK_OFFICE_CIBO_CANCELLATION_MODAL: {
      const { subscriptionId } = action.payload;
      return {
        ...state,
        cancellationModal: { subscriptionId }
      };
    }
    case ActionTypes.CLOSE_BACK_OFFICE_CIBO_CANCELLATION_MODAL:
      return {
        ...state,
        cancellationModal: null
      };
    case ActionTypes.OPEN_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL: {
      const { subscriptionId, numberOfLocationsToRemove } = action.payload;
      return {
        ...state,
        placedZonesModal: { subscriptionId, numberOfLocationsToRemove }
      };
    }
    case ActionTypes.CLOSE_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL:
      return {
        ...state,
        placedZonesModal: null
      };
    default:
      return state;
  };
};

export default backOfficeCiboSubscriptionsReducer;