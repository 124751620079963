import axios from 'axios';

import { APIResponseParsed } from 'models/response';
import {
  CiboImagerySet,
  CiboLocation,
  CiboLocationRawDataPoint,
  CiboLocationRawStatus,
  CiboSubscription
} from 'redux/modules/ciboLocations/types';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

// ==============================
// TYPES
// ==============================
export type AddCiboLocationDto = Pick<CiboLocation, 'latitude' | 'longitude' | 'enterpriseId' | 'siteId'>;
export interface UpdateCiboLocationDTO {
  name?: CiboLocation['name'];
  latitude?: CiboLocation['latitude'];
  longitude?: CiboLocation['longitude'];
}

// ==============================
// REQUESTS
// ==============================
async function fetchCiboSubscriptions(enterpriseId: number): Promise<APIResponseParsed<CiboSubscription[]>> {
  try {
    const response = await getRequest<CiboSubscription[]>(`/integrations/cibolabs?enterpriseId=${enterpriseId}`);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboLocations(enterpriseId: number): Promise<APIResponseParsed<CiboLocation[]>> {
  try {
    const response = await getRequest<CiboLocation[]>(`/integrations/cibolabs/locations?enterpriseId=${enterpriseId}`);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboLocation(locationId: number): Promise<APIResponseParsed<CiboLocation>> {
  try {
    const response = await getRequest<CiboLocation>(`/integrations/cibolabs/locations/${locationId}`);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function addCiboLocation(dto: AddCiboLocationDto): Promise<APIResponseParsed<CiboLocation>> {
  try {
    const response = await postRequest<CiboLocation, AddCiboLocationDto>('/integrations/cibolabs/locations', dto);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboImageries(enterpriseId: number): Promise<APIResponseParsed<CiboImagerySet[]>> {
  try {
    const response = await getRequest<CiboImagerySet[]>(
      `/integrations/cibolabs/locations/imagery?enterpriseId=${enterpriseId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboImagery(locationId: number): Promise<APIResponseParsed<CiboImagerySet>> {
  const retryOnError = false;
  const undefinedConfig = undefined;
  try {
    const response = await getRequest<CiboImagerySet>(
      `/integrations/cibolabs/locations/${locationId}/imagery/latest`,
      undefinedConfig,
      retryOnError
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboLocationStatus(locationId: number) {
  const retryOnError = false;
  const undefinedConfig = undefined;
  try {
    const response = await getRequest<CiboLocationRawStatus>(
      `/integrations/cibolabs/locations/${locationId}/summary`,
      undefinedConfig,
      retryOnError
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchCiboLocationDataPoints(
  locationId: number,
  startDateMs: number,
  endDateMs: number
): Promise<APIResponseParsed<CiboLocationRawDataPoint[]>> {
  try {
    const response = await getRequest<CiboLocationRawDataPoint[]>(
      `/integrations/cibolabs/locations/${locationId}/data`,
      {
        params: {
          start: startDateMs,
          end: endDateMs
        }
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function editCiboLocation(
  locationId: number,
  data: UpdateCiboLocationDTO
): Promise<APIResponseParsed<CiboLocation>> {
  try {
    const response = await patchRequest<CiboLocation, UpdateCiboLocationDTO>(
      `/integrations/cibolabs/locations/${locationId}`,
      data
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function deleteCiboLocation(locationId: number): Promise<APIResponseParsed<'OK'>> {
  try {
    await deleteRequest<unknown>(`/integrations/cibolabs/locations/${locationId}`);
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

// Grouping these functions for a namespace export, rather than named exports,
// to avoid naming collisions with action creators
export default {
  addCiboLocation,
  deleteCiboLocation,
  editCiboLocation,
  fetchCiboImageries,
  fetchCiboImagery,
  fetchCiboLocation,
  fetchCiboLocationDataPoints,
  fetchCiboLocationStatus,
  fetchCiboLocations,
  fetchCiboSubscriptions
};