/**
 * Request all the sample points for the current enterprise.
 */

import { message as antdMessage } from 'antd';
import keyBy from 'lodash/keyBy';
import { REHYDRATE } from 'redux-persist';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { notificationClient } from 'clients';
import { IPCameraEvent, SamplePointEvent } from 'models/event';
import Notification, { NotificationEventType } from 'models/notification';
import { APIResponseParsed } from 'models/response';
import { MergedSamplePoint } from 'models/samplePoint';
import NetworkActionTypes from 'redux/modules/network/constants';
import { selectNetworkOnline } from 'redux/modules/network/selectors';
import { getNotificationEventType } from 'utils/Notification/notification-event-type';
import { sanitiseNotifications } from 'utils/Notification/sanitise-notifications';

import { AssetTypeCode } from './../../../models/assetType';
import { EventType } from './../tracking/types';
import {
  acknowledgeRecentNotificationFailure,
  acknowledgeRecentNotificationSuccess,
  loadRecentNotifications,
  loadRecentNotificationsFailure,
  loadRecentNotificationsSuccess,
  removePendingAcknowledgement,
  setRecentNotification,
  setRecentNotifications
} from './actions';
import ActionTypes from './constants';
import {
  selectRecentNotifications,
  selectRecentNotificationsPendingStatusChanges
} from './selectors';
import {
  selectSamplePointsState
} from '../samplePoints/selectors';
import { SamplePointsState } from '../samplePoints/types';
import trackEvent from '../tracking/actions';

// ==============================
// PURE FUNCTIONS
// ==============================
const checkNotificationsWithoutSamplePoints = (
  notifications: Notification[],
  samplePoints: MergedSamplePoint[]
) => {
  const buggyNotifications: (Notification & { error: string })[] = [];

  for (const notification of notifications) {
    const notificationEventType = getNotificationEventType(notification.event);
    if (notificationEventType === NotificationEventType.SAMPLE_POINT_EVENT) {
      const samplePointEvent = notification.event as SamplePointEvent;
      const matchedSamplePoint: MergedSamplePoint | undefined = samplePoints.find(
        (sp) => sp.sid === samplePointEvent.samplePoint.sid ||
          sp._hidden?.sid === samplePointEvent.samplePoint.sid
      );

      if (!matchedSamplePoint) {
        buggyNotifications.push({
          ...notification,
          error:
            'Enterprise doesn\'t have the sample points showed in notifications'
        });
      }
    }
  }

  return buggyNotifications;
};

// ==============================
// SAGAS
// ==============================
function* requestRecentNotifications(
  action: ReturnType<typeof loadRecentNotifications>
): SagaIterator {
  const {
    payload: { enterpriseId, isInitialLoading }
  } = action;
  const response: APIResponseParsed<Notification[]> = yield call(notificationClient.fetchNotifications, enterpriseId);
  if (response.data) {
    const { data } = response;
    const notifications: Notification[] = sanitiseNotifications(data);

    yield all([
      put(setRecentNotifications(enterpriseId, keyBy(notifications, 'id'))),
      put(loadRecentNotificationsSuccess())
    ]);
  } else {
    const errorMessage = response.error.message || 'Sorry, something went wrong.';
    yield put(loadRecentNotificationsFailure(errorMessage));
  }
}

function* acknowledgeRecentNotifications() {
  const networkOnline: ReturnType<typeof selectNetworkOnline> =
    yield select(selectNetworkOnline);

  const notifications: Record<string, Notification> = yield select(
    selectRecentNotifications
  );
  const samplePointState: SamplePointsState = yield select(
    selectSamplePointsState
  );

  const pendingStatusChanges: ReturnType<
    typeof selectRecentNotificationsPendingStatusChanges
  > = yield select(selectRecentNotificationsPendingStatusChanges);

  const notificationIds = Object.keys(pendingStatusChanges);

  if (networkOnline) {
    for (let i = 0; i < notificationIds.length; i += 1) {
      const notificationId = Number(notificationIds[i]);
      const notificationStatusChange = pendingStatusChanges[notificationId];
      const event = notifications[notificationId].event;
      const eventType = getNotificationEventType(event);

      let trackingData;

      if (eventType === NotificationEventType.SAMPLE_POINT_EVENT) {
        const samplePointEvent = event as SamplePointEvent;

        trackingData = {
          type: EventType.NOTIFICATION_ACKNOWLEDGED,
          data: {
            status: true,
            samplePointId: samplePointEvent.samplePoint.id,
            assetTypeId: samplePointState[samplePointEvent.samplePoint.id].assetTypeId,
            eventLevel: samplePointEvent.level,
            eventMessage: samplePointEvent.message
          }
        };
      } else if (eventType === NotificationEventType.IP_CAMERA_EVENT) {
        const ipCameraEvent = event as IPCameraEvent;

        trackingData = {
          type: EventType.THIRD_PARTY_NOTIFICATION_ACKNOWLEDGED,
          data: {
            status: true,
            ipCameraId: ipCameraEvent.ipCamera.id,
            assetTypeId: AssetTypeCode.SECURITY_CAMERA,
            eventLevel: ipCameraEvent.level,
            eventMessage: ipCameraEvent.message
          }
        };
      }

      const response: APIResponseParsed<Notification> = yield call(
        notificationClient.patchNotification,
        notificationId,
        notificationStatusChange
      );
      if (response.data) {
        yield all([
          put(removePendingAcknowledgement(notificationId)),
          put(setRecentNotification(response.data)),
          put(trackEvent(trackingData)),
          put(acknowledgeRecentNotificationSuccess())
        ]);
      } else {
        // Suppress error handling in the event of a Network Error.
        if (response.error.isNetworkError) return;

        const errorMessage = response.error.message || 'Sorry, something went wrong.';
        antdMessage.error('Failed to acknowledge the notification. Please try again.');

        const trackingEventType: EventType = eventType === NotificationEventType.IP_CAMERA_EVENT
          ? EventType.THIRD_PARTY_NOTIFICATION_ACKNOWLEDGED
          : EventType.NOTIFICATION_ACKNOWLEDGED;

        yield all([
          put(removePendingAcknowledgement(notificationId)),
          put(trackEvent({
            type: trackingEventType,
            data: { ...trackingData, status: false }
          })),
          acknowledgeRecentNotificationFailure(errorMessage)
        ]);
      }
    }
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchLoadRecentNotificationsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_RECENT_NOTIFICATIONS_REQUEST,
    requestRecentNotifications
  );
}

function* watchSetPendingAcknowledgement() {
  yield takeLatest(
    [
      REHYDRATE,
      NetworkActionTypes.SET_NETWORK_ONLINE,
      ActionTypes.SET_PENDING_ACKNOWLEDGEMENT
    ],
    acknowledgeRecentNotifications
  );
}

// ==============================
// EXPORT
// ==============================
export default function* recentNotificationsSaga() {
  yield all([
    fork(watchLoadRecentNotificationsRequest),
    fork(watchSetPendingAcknowledgement)
  ]);
}
