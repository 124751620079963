import { DefaultDateRanges, RainGaugeDateRanges, SafetyCheckInDateRages } from 'redux/modules/localUserSettings/types';

export const MAX_ASSOCIATED_LORA_DEVICES: number = 11;

export const OFFSET_MAX_CM: number = 600;
export const OFFSET_MAX_IN: number = 240;
export const OFFSET_MIN_CM: number = -600;
export const OFFSET_MIN_IN: number = -240;

export const BORE_MAX_DEPTH_M: number = 300;
export const BORE_MAX_DEPTH_FT: number = 1000;

export const LINE_PRESSURE_MAX_KPA: number = 4000;
export const LINE_PRESSURE_MAX_PSI: number = 600;

export const TANK_MAX_HEIGHT_CM: number = 900;
export const TANK_MAX_HEIGHT_IN: number = 790;

export const TROUGH_MAX_HEIGHT_CM: number = 125;
export const TROUGH_MAX_HEIGHT_IN: number = 50;

export const DEFAULT_PRECISION_IMPERIAL: number = 2;
export const DEFAULT_PRECISION_METRIC: number = 1;
export const DEFAULT_PERCENTAGE_PRECISION: number = 0;
export const WATER_TANK_LEVEL_PRECISION: number = 1;
export const WATER_TANK_VOLUME_PRECISION: number = 0;
export const DAM_PRECISION: number = 2;
export const LINE_PRESSURE_PRECISION: number = 1;
export const LIQUID_FERTILISER_TANK_VOLUME_PRECISION = WATER_TANK_VOLUME_PRECISION;
export const BORE_LEVEL_PRECISION: number = 2;
// Cannot be higher because the trigger precision is limited to 0.01 m by BE.
// For example, 3.22 ft is 0.98m (0.9815m) but 3.23 ft is also 0.98m (0.9845m).
export const BORE_LEVEL_TRIGGER_PRECISION_IMPERIAL: number = 1;
export const PIPE_FLOW_RATE_PRECISION: number = 1;
export const PIPE_VOLUME_PRECISION_IMPERIAL: number = 1;
export const PIPE_VOLUME_PRECISION_METRIC: number = 0;
export const RAIN_GAUGE_PRECISION_IMPERIAL: number = 2;
export const RAIN_GAUGE_PRECISION_METRIC: number = 1;
export const WIRELESS_TROUGH_LEVEL_PRECISION: number = 1;
export const TROUGH_DIRECT_PRECISION: number = 1;
export const TROUGH_DIRECT_DEFAULT_HEIGHT_CM: number = 15;
export const TROUGH_DIRECT_DEFAULT_HEIGHT_IN: number = 6;

export const CHART_DEFAULT_DATE_RANGE: DefaultDateRanges = 'day.14';
export const RAIN_GAUGE_CHART_DEFAULT_DATE_RANGE: RainGaugeDateRanges = 'day.30';
export const FLOW_CHART_DEFAULT_DATE_RANGE: DefaultDateRanges = 'hour.48';
export const SAFETY_CHECK_IN_DEFAULT_DATE_RANGE: SafetyCheckInDateRages = 'month.1';

export const LEGACY_DAILY_RAINFALL_RESET_HOUR: number = 0;
export const DEFAULT_DAILY_RAINFALL_RESET_HOUR: number = 9;
export const DEFAULT_DAILY_RAINFALL_RESET_HOUR_US: number = 7;
