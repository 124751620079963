import { IPCameraEvent, SamplePointEvent } from 'models/event';
import Notification, { NotificationEventType } from 'models/notification';

export const getNotificationEventType = (event: SamplePointEvent | IPCameraEvent) => {
  if ('samplePoint' in event) {
    return NotificationEventType.SAMPLE_POINT_EVENT;
  } else if ('ipCamera' in event) {
    return NotificationEventType.IP_CAMERA_EVENT;
  } else {
    return false;
  }
};

export const getSamplePointEventFromNotification = (
  notification: Notification | null | undefined
): SamplePointEvent | null => {
  if (!notification) return null;

  if (notification.eventType === NotificationEventType.SAMPLE_POINT_EVENT) {
    return notification.event as SamplePointEvent;
  }

  return null;
};