import axios, { AxiosError } from 'axios';

import HttpStatusCode from 'models/httpStatusCode.enum';
import { APIErrorParsed } from 'models/response';

const MESSAGES = {
  NETWORK_ERROR: 'Network Error'
};

interface APIErrorRaw {
  message: string | string[];
  statusCode: HttpStatusCode;
}

export const parseAxiosError = (error: AxiosError): APIErrorParsed => {
  // Asserts the shape of the error must conform to APIErrorRaw
  if (!axios.isAxiosError<APIErrorRaw>(error)) {
    return {
      message: error.message,
      httpStatusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
      isNetworkError: error.message === MESSAGES.NETWORK_ERROR
    };
  }

  const { response } = error;

  let message = '';
  if (response?.data.message) {
    message = Array.isArray(response.data.message)
      ? response.data.message.join(', ')
      : response.data.message;
  }

  return {
    message,
    httpStatusCode: response?.data.statusCode || response?.status || HttpStatusCode.INTERNAL_SERVER_ERROR,
    isNetworkError: error.message === MESSAGES.NETWORK_ERROR
  };
};
