import { EventSamplePoint, SamplePointEvent } from 'models/event';
import Notification, { NotificationEventType } from 'models/notification';

import { getNotificationEventType } from './notification-event-type';

export const getEventSamplePointFromNotification = (
  notification: Notification | null | undefined
): EventSamplePoint | null => {
  if (!notification) return null;

  if (getNotificationEventType(notification.event) === NotificationEventType.SAMPLE_POINT_EVENT) {
    const event = notification.event as SamplePointEvent;
    return event.samplePoint;
  }

  return null;
};