import axios from 'axios';

import { DeviceNotificationPolicy, ExternalDeviceNotificationPolicy } from 'models/notificationPolicy';
import { APIResponseParsed } from 'models/response';
import { getRequest, putRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

async function fetchNotificationPolicies(siteId: number): Promise<APIResponseParsed<DeviceNotificationPolicy[]>> {
  const path = `notification-policy/site/${siteId}`;
  try {
    const response = await getRequest<DeviceNotificationPolicy[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchExternalDeviceNotificationPolicies(
  siteId: number
): Promise<APIResponseParsed<ExternalDeviceNotificationPolicy[]>> {
  const path = `notification-policy/site/${siteId}/external`;
  try {
    const response = await getRequest<ExternalDeviceNotificationPolicy[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function editExternalDeviceNotificationPolicies(
  siteId: number,
  externalNotificationPolicies: ExternalDeviceNotificationPolicy[]
): Promise<APIResponseParsed<ExternalDeviceNotificationPolicy[]>> {
  const path = `notification-policy/site/${siteId}/external`;
  try {
    const response = await putRequest<ExternalDeviceNotificationPolicy[], ExternalDeviceNotificationPolicy[]>(
      path,
      externalNotificationPolicies
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

// Grouping these functions for a namespace export, rather than named exports,
// to avoid naming collisions with action creators
export default {
  editExternalDeviceNotificationPolicies,
  fetchExternalDeviceNotificationPolicies,
  fetchNotificationPolicies
};