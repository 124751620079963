import { EventSite, IPCameraEvent, SamplePointEvent } from 'models/event';
import Notification, { NotificationEventType } from 'models/notification';
import { SiteId } from 'models/site';

export const getNotificationSiteId = (notification: Notification): SiteId | null => {
  if (notification.eventType === NotificationEventType.SAMPLE_POINT_EVENT) {
    const event = notification.event as SamplePointEvent;
    return event.samplePoint.siteId;
  } else if (notification.eventType === NotificationEventType.IP_CAMERA_EVENT) {
    const event = notification.event as IPCameraEvent;
    return event.ipCamera.siteId;
  }

  return null;
};

export const getNotificationEventSite = (notification: Notification): EventSite | null => {
  if (notification.eventType === NotificationEventType.SAMPLE_POINT_EVENT) {
    const event = notification.event as SamplePointEvent;
    return event.samplePoint.site;
  } else if (notification.eventType === NotificationEventType.IP_CAMERA_EVENT) {
    const event = notification.event as IPCameraEvent;
    return event.ipCamera.site;
  }

  return null;
};