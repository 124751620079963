import React from 'react';

import NewTabLink from 'components/atoms/NewTabLink';
import Enterprise from 'models/enterprise';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';

interface Props {
  enterprise: Enterprise;
  iconTop?: number;
  disabled?: boolean;
}

function EnterpriseLink({
  enterprise,
  disabled = false
}: Props): JSX.Element {
  const href = constructEnterpriseDomain(enterprise);

  return <NewTabLink disabled={disabled} href={href} iconSize={22} />;
}

export default EnterpriseLink;
