import { useEnterpriseCountry } from 'redux/modules/enterprise/hooks';

export const SymbolToUnit = {
  cm: {
    name: {
      singular: 'Centimetre',
      plural: 'Centimetres'
    }
  },
  in: {
    name: {
      singular: 'Inch',
      plural: 'Inches'
    }
  },
  m: {
    name: {
      singular: 'Metre',
      plural: 'Metres'
    }
  },
  ft: {
    name: {
      singular: 'Foot',
      plural: 'Feet'
    }
  }
};

export enum UnitType {
  /**
   * cm or in
   */
  LEVEL_SM = 'LEVEL_SM',
  /**
   * metre or foot.
   * We don't name it as LEVEL_M as M could be mistaken for 'metre'.
   */
  LEVEL_MD = 'LEVEL_MD',
  FLOW = 'FLOW',
  /** litre or gallon */
  VOLUME_SM = 'VOLUME_SM',
  /** kilolitre or cubic-foot */
  VOLUME_MD = 'VOLUME_MD',
  /**
   * megalitre or acre-foot.
   * We don't name it as VOLUME_L as L could be mistaken for 'litre'.
   */
  VOLUME_LG = 'VOLUME_LG',
  /** cm/h or in/h */
  TREND_SM = 'TREND_SM',
  /** metre/h or ft/h */
  TREND_MD = 'TREND_MD',
  RAIN = 'RAIN',
  SIZE = 'SIZE',
  PRESSURE = 'PRESSURE',
  AREA_SM = 'AREA_SM',
  MATTER = 'MATTER'
}

interface GetUnitByCountry {
  unitType: UnitType;
  country?: string;
}

type UnitAreaSm = 'm²' | 'ft²';
type UnitFlow = 'L/min' | 'gal/min';
type UnitRain = 'mm' | 'in';
type UnitTrendMd = 'm/h' | 'ft/h';
type UnitTrendSm = 'cm/h' | 'in/h';
type UnitVolumeLg = 'ML' | 'ac. ft.';
type UnitVolumeMd = 'KL' | 'ft³';
type UnitVolumeSm = 'L' | 'gal';
export type UnitLevelMd = 'm' | 'ft';
export type UnitLevelSm = 'cm' | 'in';
export type UnitMatter = 'kg/ha' | 'lb/ac';
export type UnitPressure = 'kPa' | 'psi';
export type UnitSize = 'ha' | 'ac';

export type UnitSymbol =
  | UnitLevelSm
  | UnitLevelMd
  | UnitFlow
  | UnitVolumeSm
  | UnitVolumeMd
  | UnitVolumeLg
  | UnitTrendSm
  | UnitTrendMd
  | UnitRain
  | UnitSize
  | UnitPressure
  | UnitAreaSm
  | UnitMatter;

/**
 * @returns Unit symbol
 */
export function getUnitByCountry({
  unitType,
  country
}: GetUnitByCountry): UnitSymbol {
  const isUS = country === 'USA';
  // @uom-refactor to return a unit object which contains
  // both short & long name and symbol etc. And the unit can be used as a
  // type-safe parameter to construct a quantity object.
  switch (unitType) {
    case UnitType.LEVEL_SM:
      return isUS ? 'in' : 'cm';
    case UnitType.LEVEL_MD:
      return isUS ? 'ft' : 'm';
    case UnitType.FLOW:
      return isUS ? 'gal/min' : 'L/min';
    case UnitType.VOLUME_SM:
      return isUS ? 'gal' : 'L';
    case UnitType.VOLUME_MD:
      return isUS ? 'ft³' : 'KL';
    case UnitType.VOLUME_LG:
      return isUS ? 'ac. ft.' : 'ML';
    case UnitType.TREND_SM:
      return isUS ? 'in/h' : 'cm/h';
    case UnitType.TREND_MD:
      return isUS ? 'ft/h' : 'm/h';
    case UnitType.RAIN:
      return isUS ? 'in' : 'mm';
    case UnitType.SIZE:
      return isUS ? 'ac' : 'ha';
    case UnitType.PRESSURE:
      return isUS ? 'psi' : 'kPa';
    case UnitType.AREA_SM:
      return isUS ? 'ft²' : 'm²';
    case UnitType.MATTER:
      return isUS ? 'lb/ac' : 'kg/ha';
    default:
      return 'cm';
  }
}

export function useGetLocaleUnit(unitType: UnitType) {
  const country = useEnterpriseCountry();
  return getUnitByCountry({ unitType, country });
}
