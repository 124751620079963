import { AssetTypeCode } from 'models/assetType';

import { ONE_MINUTE_IN_MS } from './time';

export const integratedAssetTypes = [AssetTypeCode.SECURITY_CAMERA];
export const CIBO_MATTER_PRECISION = 0;
export const CIBO_AREA_PRECISION = 0;
export const CIBO_LOCATION_ADJUSTMENT_PERIOD_IN_DAYS = 14;
export const CIBO_SUGGESTED_ZOOM_LEVEL: number = 11;
export const CIBO_DATA_AND_IMAGERY_POLLING_INTERVAL_IN_MS = 1.5 * ONE_MINUTE_IN_MS;
