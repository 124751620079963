import { message as antdMessage } from 'antd';
import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { notificationPolicyClient } from 'clients';
import { DeviceNotificationPolicy, ExternalDeviceNotificationPolicy } from 'models/notificationPolicy';
import { APIResponseParsed } from 'models/response';
import { putRequest } from 'utils/redux-saga-requests';

import {
  editExternalDeviceNotificationPolicies,
  editExternalDeviceNotificationPoliciesFailure,
  editExternalDeviceNotificationPoliciesSuccess,
  editNotificationPolicies,
  editNotificationPoliciesFailure,
  editNotificationPoliciesSuccess,
  fetchExternalDeviceNotificationPolicies,
  fetchExternalDeviceNotificationPoliciesFailure,
  fetchExternalDeviceNotificationPoliciesSuccess,
  fetchNotificationPolicies,
  fetchNotificationPoliciesFailure,
  fetchNotificationPoliciesSuccess,
  setExternalDeviceNotificationPolicies,
  setNotificationPolicies
} from './actions';
import ActionTypes from './constants';

// ==============================
// SAGAS
// ==============================
function* requestNotificationPolicies(
  action: ReturnType<typeof fetchNotificationPolicies>
): SagaIterator {
  const { payload: { siteId } } = action;
  const response: APIResponseParsed<DeviceNotificationPolicy[]> = yield call(
    notificationPolicyClient.fetchNotificationPolicies,
    siteId
  );
  if (response.data) {
    yield all([
      put(setNotificationPolicies(siteId, response.data)),
      put(fetchNotificationPoliciesSuccess())
    ]);
  } else {
    const message = response.error.message || 'Sorry, something went wrong.';
    yield put(fetchNotificationPoliciesFailure(message));
  }
}

function* requestEditNotificationPolicies(
  action: ReturnType<typeof editNotificationPolicies>
): SagaIterator {
  const {
    payload: { siteId, deviceNotificationPolicies }
  } = action;

  // FMBT-1728 Temporarily workaround
  const updatedNotificationPolicies = deviceNotificationPolicies.map((policy) => ({
    ...policy,
    userIds: policy.userIds.filter((id) => typeof id === 'number')
  }));

  try {
    const response = yield call(
      putRequest,
      `notification-policy/site/${siteId}`,
      updatedNotificationPolicies
    );

    const { data } = response;

    yield all([
      put(setNotificationPolicies(siteId, data)),
      put(editNotificationPoliciesSuccess(response))
    ]);

    antdMessage.success('Team notifications updated');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to update team notifications');
    yield put(editNotificationPoliciesFailure(message, error));
  }
}

function* requestExternalDeviceNotificationPolicies(
  action: ReturnType<typeof fetchExternalDeviceNotificationPolicies>
): SagaIterator {
  const { payload: { siteId } } = action;
  const response: APIResponseParsed<ExternalDeviceNotificationPolicy[]> = yield call(
    notificationPolicyClient.fetchExternalDeviceNotificationPolicies,
    siteId
  );
  if (response.data) {
    yield all([
      put(setExternalDeviceNotificationPolicies(siteId, response.data)),
      put(fetchExternalDeviceNotificationPoliciesSuccess())
    ]);
  } else {
    const message = response.error.message || 'Sorry, something went wrong.';
    yield put(fetchExternalDeviceNotificationPoliciesFailure(message));
  }
}

function* requestEditExternalDeviceNotificationPolicies(
  action: ReturnType<typeof editExternalDeviceNotificationPolicies>
): SagaIterator {
  const {
    payload: { siteId, notificationPolicies }
  } = action;
  const response: APIResponseParsed<ExternalDeviceNotificationPolicy[]> = yield call(
    notificationPolicyClient.editExternalDeviceNotificationPolicies,
    siteId,
    notificationPolicies
  );
  if (response.data) {
    yield all([
      put(setExternalDeviceNotificationPolicies(siteId, response.data)),
      put(editExternalDeviceNotificationPoliciesSuccess())
    ]);
    antdMessage.success('Team notifications updated');
  } else {
    const message = response.error.message || 'Sorry, something went wrong.';
    yield put(editExternalDeviceNotificationPoliciesFailure(message));
    antdMessage.error('Failed to update team notifications');
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchFetchNotificationPoliciesRequest() {
  yield takeLatest(
    ActionTypes.FETCH_NOTIFICATION_POLICIES_REQUEST,
    requestNotificationPolicies
  );
}

function* watchEditNotificationPoliciesRequest() {
  yield takeLatest(
    ActionTypes.EDIT_NOTIFICATION_POLICIES_REQUEST,
    requestEditNotificationPolicies
  );
}

function* watchFetchExternalDeviceNotificationPoliciesRequest() {
  yield takeLatest(
    ActionTypes.FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST,
    requestExternalDeviceNotificationPolicies
  );
}

function* watchEditExternalDeviceNotificationPoliciesRequest() {
  yield takeLatest(
    ActionTypes.EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST,
    requestEditExternalDeviceNotificationPolicies
  );
}

// ==============================
// EXPORT
// ==============================
export default function* notificationPolicySaga() {
  yield all([
    fork(watchEditExternalDeviceNotificationPoliciesRequest),
    fork(watchEditNotificationPoliciesRequest),
    fork(watchFetchExternalDeviceNotificationPoliciesRequest),
    fork(watchFetchNotificationPoliciesRequest)
  ]);
}
