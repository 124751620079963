import { message as antdMessage } from 'antd';
import keyBy from 'lodash/keyBy';
import { all, call, fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { ipCameraClient } from 'clients';
import IPCamera, {
  IPCameraCapabilities,
  IPCameraEvent,
  IPCameraMetadata,
  IPCameraPhoto,
  IPCameraPreview,
  PTZPreset
} from 'models/ipCamera';
import { APIResponseParsed, PaginatedResponse } from 'models/response';
import { EntityKind } from 'models/userPreference';
import { unsetMyPreference } from 'redux/modules/myPreferences/actions';
import { removeLastSubPath } from 'redux/modules/routerUtils/actions';

import {
  activateIPCameraPTZPresetFailure,
  activateIPCameraPTZPresetRequest,
  activateIPCameraPTZPresetSuccess,
  appendIPCameraEvents,
  createIPCameraPTZPresetFailure,
  createIPCameraPTZPresetRequest,
  createIPCameraPTZPresetSuccess,
  deleteIPCameraPTZPresetFailure,
  deleteIPCameraPTZPresetRequest,
  deleteIPCameraPTZPresetSuccess,
  editIPCamera,
  editIPCameraFailure,
  editIPCameraSuccess,
  fetchCameraById,
  fetchCameraByIdSuccess,
  fetchCamerasBySite,
  fetchCamerasBySiteFailure,
  fetchCamerasBySiteSuccess,
  fetchIPCameraCapabilities,
  fetchIPCameraCapabilitiesFailure,
  fetchIPCameraCapabilitiesSuccess,
  fetchIPCameraEvents,
  fetchIPCameraEventsFailure,
  fetchIPCameraEventsSuccess,
  fetchIPCameraPTZPresetsFailure,
  fetchIPCameraPTZPresetsRequest,
  fetchIPCameraPTZPresetsSuccess,
  fetchIPCameraPhotos,
  fetchIPCameraPhotosFailure,
  fetchIPCameraPhotosSuccess,
  fetchIPCameraState,
  fetchIPCameraStateFailure,
  fetchIPCameraStateSuccess,
  removeFromIPCameraList,
  removeIPCamera,
  removeIPCameraPTZPreset,
  removeIPCameraSuccess,
  resetIPCameraUnseenPhoto,
  sendCameraPTZControlRequest,
  setIPCameraCapabilities,
  setIPCameraEvents,
  setIPCameraPTZPreset,
  setIPCameraPTZPresets,
  setIPCameraPhotos,
  setIPCameraPreview,
  setIPCameraPreviewError,
  setIPCameraState,
  setIPCameraUnseenPhoto,
  takeIPCameraPhoto,
  takeIPCameraPhotoFailure,
  takeIPCameraPhotoSuccess,
  takeIPCameraPreview,
  takeIPCameraPreviewFailure,
  takeIPCameraPreviewSuccess,
  updateIPCameraPTZPresetFailure,
  updateIPCameraPTZPresetRequest,
  updateIPCameraPTZPresetSuccess
} from './actions';
import ActionTypes from './constants';
import { selectIPCamera } from './selector';
import { IPCamerasState } from './types';

// ==============================
// SAGAS
// ==============================
function* requestIPCamerasBySite(action: ReturnType<typeof fetchCamerasBySite>) {
  const { payload: { enterpriseId, siteId } } = action;
  const response: APIResponseParsed<IPCamera[]> = yield call(ipCameraClient.fetchIPCamerasBySite, enterpriseId, siteId);
  if (response.data) {
    // TODO: create proper mapping object; introduce DTOs for FE
    const ipCameras: IPCamerasState = keyBy(response.data, 'id');
    yield put(fetchCamerasBySiteSuccess(ipCameras));
  } else {
    yield put(fetchCamerasBySiteFailure('Failed to retrieve Security Cameras'));
  }
}

function* requestIPCameraById(action: ReturnType<typeof fetchCameraById>) {
  const { payload: { id } } = action;
  const response: APIResponseParsed<IPCamera> = yield call(ipCameraClient.fetchIPCameraById, id);
  const metadataResponse: APIResponseParsed<IPCameraMetadata> = yield call(
    ipCameraClient.fetchIPCameraONVIFStateById,
    id
  );

  if (response.data && metadataResponse.data) {
    const ipCamera: IPCamera = {
      ...response.data,
      metadata: metadataResponse.data
    };
    yield put(fetchCameraByIdSuccess(ipCamera));
  } else {
    antdMessage.error('Failed to retrieve Security Camera');
  }
}

function* requestTakeIPCameraPreview(action: ReturnType<typeof takeIPCameraPreview>) {
  const { payload: { ipCameraId } } = action;
  const response: APIResponseParsed<IPCameraPreview> = yield call(ipCameraClient.takeIPCameraPreview, ipCameraId);

  if (response.data) {
    yield all([
      put(takeIPCameraPreviewSuccess()),
      put(setIPCameraPreview(ipCameraId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to take a preview';
    yield all([
      put(takeIPCameraPreviewFailure(message)),
      put(setIPCameraPreviewError(ipCameraId, message))
    ]);
  }
}

function* requestFetchIPCameraState(action: ReturnType<typeof fetchIPCameraState>) {
  const { payload: { ipCameraId } } = action;
  const response: APIResponseParsed<IPCameraMetadata> = yield call(
    ipCameraClient.fetchIPCameraONVIFStateById,
    ipCameraId
  );

  if (response.data) {
    yield all([
      put(fetchIPCameraStateSuccess()),
      put(setIPCameraState(ipCameraId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to retrieve Security Camera state';
    yield put(fetchIPCameraStateFailure(message));

    const unknownState = { status: { online: false }, capabilities: { ptz: false } };
    yield put(setIPCameraState(ipCameraId, { ...unknownState, error: message }));
  }
}

function* requestFetchIPCameraCapabilities(action: ReturnType<typeof fetchIPCameraCapabilities>) {
  const { payload: { ipCameraId, capabilityFields } } = action;
  const response: APIResponseParsed<Partial<IPCameraCapabilities>> = yield call(
    ipCameraClient.fetchIPCameraCapabilities,
    ipCameraId,
    capabilityFields
  );

  if (response.data) {
    yield all([
      put(fetchIPCameraCapabilitiesSuccess()),
      put(setIPCameraCapabilities(ipCameraId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to retrieve Security Camera capabilities';
    yield put(fetchIPCameraCapabilitiesFailure(message));
  }
}

function* requestFetchIPCameraPhotos(action: ReturnType<typeof fetchIPCameraPhotos>) {
  const { payload: { ipCameraId, params } } = action;
  const response: APIResponseParsed<PaginatedResponse<IPCameraPhoto>> = yield call(
    ipCameraClient.fetchIPCameraPhotos,
    ipCameraId,
    params
  );

  if (response.data) {
    const { count, data: photos } = response.data;
    yield all([
      put(fetchIPCameraPhotosSuccess()),
      put(setIPCameraPhotos(ipCameraId, { currentPage: params.page, currentData: photos, totalDataItems: count })),
      put(resetIPCameraUnseenPhoto(ipCameraId))
    ]);
  } else {
    const message = response.error.message || 'Sorry, something went wrong.';
    yield put(fetchIPCameraPhotosFailure(message));
  }
}

function* requestTakeIPCameraPhoto(action: ReturnType<typeof takeIPCameraPhoto>) {
  const { payload: { ipCameraId } } = action;
  const response: APIResponseParsed<IPCameraPhoto> = yield call(ipCameraClient.takeIPCameraSnapshot, ipCameraId);
  if (response.data) {
    yield all([
      put(takeIPCameraPhotoSuccess()),
      put(setIPCameraUnseenPhoto(ipCameraId, response.data))
    ]);
    antdMessage.success('Photo taken');
  } else {
    const message = response.error.message || 'Failed to take Security Camera photo';
    yield put(takeIPCameraPhotoFailure(message));
    antdMessage.error('Failed to take photo');
  }
}

function* requestIPCameraPTZControl(action: ReturnType<typeof sendCameraPTZControlRequest>) {
  const { payload: { id, control } } = action;
  const response: APIResponseParsed<'OK'> = yield call(ipCameraClient.sendIPCameraControl, id, control);

  if (response.data) {
    // Do nothing
  } else {
    antdMessage.error('Unable to control Security Camera PTZ');
  }
}

function* requestIPCameraEvents(action: ReturnType<typeof fetchIPCameraEvents>) {
  const { payload: { ipCameraId, startDate, endDate, isAppend } } = action;
  const response: APIResponseParsed<IPCameraEvent[]> = yield call(
    ipCameraClient.fetchIPCameraEvents,
    ipCameraId,
    startDate,
    endDate
  );

  if (response.data) {
    const { data } = response;
    if (data.length > 0) {
      if (isAppend) {
        yield put(appendIPCameraEvents(ipCameraId, data));
      } else {
        yield put(setIPCameraEvents(ipCameraId, data));
      }
    } else if (data.length === 0) {
      if (isAppend) {
        antdMessage.info('No more recordings in this date range');
      } else {
        antdMessage.info('No recordings found');

        // This helps initialise the eventList in DetailsPageContext, to distinguish between
        // no requests made vs. no events found
        yield put(setIPCameraEvents(ipCameraId, []));
      }
    }

    yield put(fetchIPCameraEventsSuccess());
  } else {
    const message = response.error.message || 'Failed to retrieve Security Camera recordings';
    yield put(fetchIPCameraEventsFailure(message));
    antdMessage.error('Failed to retrieve recordings');
  }
}

function* requestEditIPCamera(action: ReturnType<typeof editIPCamera>) {
  const { payload: { id, values } } = action;
  const response: APIResponseParsed<IPCamera> = yield call(ipCameraClient.editIPCamera, id, values);
  if (response.data) {
    yield all([
      put(editIPCameraSuccess()),
      // Repurpose the following action to save the updated Security Camera to the store
      put(fetchCameraByIdSuccess(response.data))
    ]);
    antdMessage.success('Security Camera updated');
  } else {
    const message = response.error.message || 'Failed to update Security Camera';
    yield put(editIPCameraFailure(message));
    antdMessage.error('Failed to update Security Camera');
  }
}

function* requestRemoveIPCamera(action: ReturnType<typeof removeIPCamera>) {
  const { payload: { id } } = action;
  const response: APIResponseParsed<'OK'> = yield call(ipCameraClient.deleteIPCamera, id);
  if (response.data) {
    yield all([
      put(removeIPCameraSuccess()),
      put(removeLastSubPath()),
      put(removeFromIPCameraList(id)),
      put(unsetMyPreference(EntityKind.IP_CAMERA, id))
    ]);
    antdMessage.success('Security Camera removed');
  } else {
    const message = response.error.message || 'Failed to remove Security Camera';
    yield put(editIPCameraFailure(message));
    antdMessage.error('Failed to remove Security Camera');
  }
}

function* requestFetchIPCameraPTZPresets(action: ReturnType<typeof fetchIPCameraPTZPresetsRequest>) {
  const { payload: { ipCameraId } } = action;
  const ipCamera: IPCamera = yield select(selectIPCamera(ipCameraId));
  const enterpriseId = ipCamera.landwatchIntegration?.enterpriseId;

  if (!enterpriseId) throw new Error('Missing enterpriseId from ipCamera');

  const response: APIResponseParsed<PTZPreset[]> = yield call(
    ipCameraClient.fetchIPCameraPTZPresets,
    enterpriseId,
    ipCameraId
  );
  if (response.data) {
    yield all([
      put(fetchIPCameraPTZPresetsSuccess()),
      put(setIPCameraPTZPresets(ipCameraId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to retrieve Security Camera PTZ Presets';
    yield put(fetchIPCameraPTZPresetsFailure(message));
    antdMessage.error('Failed to get presets');
  }
}

function* requestCreateIPCameraPTZPreset(action: ReturnType<typeof createIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId } } = action;
  const response: APIResponseParsed<PTZPreset> = yield call(ipCameraClient.createIPCameraPTZPreset, ipCameraId);

  if (response.data) {
    yield all([
      put(createIPCameraPTZPresetSuccess()),
      put(setIPCameraPTZPreset(ipCameraId, response.data))
    ]);
    antdMessage.success('Preset created');
  } else {
    const message = response.error.message || 'Failed to create Security Camera PTZ Preset';
    yield put(createIPCameraPTZPresetFailure(message));
    antdMessage.error('Failed to create preset');
  }
}

function* requestUpdateIPCameraPTZPreset(action: ReturnType<typeof updateIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId, values } } = action;
  const response: APIResponseParsed<PTZPreset> = yield call(
    ipCameraClient.updateIPCameraPTZPreset,
    ipCameraId,
    presetId,
    values
  );
  if (response.data) {
    yield all([
      put(updateIPCameraPTZPresetSuccess()),
      put(setIPCameraPTZPreset(ipCameraId, response.data))
    ]);
    antdMessage.success('Preset updated');
  } else {
    const message = response.error.message || 'Failed to update Security Camera PTZ Preset';
    yield put(updateIPCameraPTZPresetFailure(message));
    antdMessage.error('Failed to update preset');
  }
}

function* requestDeleteIPCameraPTZPreset(action: ReturnType<typeof deleteIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId } } = action;
  const response: APIResponseParsed<'OK'> = yield call(ipCameraClient.deleteIPCameraPTZPreset, ipCameraId, presetId);
  if (response.data) {
    yield all([
      put(deleteIPCameraPTZPresetSuccess()),
      put(removeIPCameraPTZPreset(ipCameraId, presetId))
    ]);
    antdMessage.success('Preset deleted');
  } else {
    const message = response.error.message || 'Failed to delete Security Camera PTZ Preset';
    yield put(deleteIPCameraPTZPresetFailure(message));
    antdMessage.error('Failed to delete preset');
  }
}

function* requestActivatePTZRequest(action: ReturnType<typeof activateIPCameraPTZPresetRequest>) {
  const { payload: { ipCameraId, presetId } } = action;
  const response: APIResponseParsed<'OK'> = yield call(ipCameraClient.activateIPCameraPTZPreset, ipCameraId, presetId);
  if (response.data) {
    yield put(activateIPCameraPTZPresetSuccess());
    antdMessage.success('Preset activated');
  } else {
    const message = response.error.message || 'Failed to activate Security Camera PTZ Preset';
    yield put(activateIPCameraPTZPresetFailure(message));
    antdMessage.error('Failed to activate preset');
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchIPCamerasListBySiteRequest() {
  yield takeLatest(ActionTypes.FETCH_CAMERAS_BY_SITE_REQUEST, requestIPCamerasBySite);
}

function* watchIPCameraByIdRequest() {
  yield takeLatest(ActionTypes.FETCH_CAMERA_BY_ID_REQUEST, requestIPCameraById);
}

function* watchTakeIPCameraPreviewRequest() {
  yield takeEvery(ActionTypes.TAKE_IP_CAMERA_PREVIEW_REQUEST, requestTakeIPCameraPreview);
}

function* watchFetchIPCameraStateRequest() {
  yield takeEvery(ActionTypes.FETCH_IP_CAMERA_STATE_REQUEST, requestFetchIPCameraState);
}

function* watchFetchIPCameraCapabilitiesRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_CAPABILITIES_REQUEST, requestFetchIPCameraCapabilities);
}

function* watchFetchIPCameraPhotosRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_PHOTOS_REQUEST, requestFetchIPCameraPhotos);
}

function* watchTakeIPCameraPhotoRequest() {
  yield takeLatest(ActionTypes.TAKE_IP_CAMERA_PHOTO_REQUEST, requestTakeIPCameraPhoto);
}

function* watchIPCameraPTZControlRequest() {
  yield takeLeading(ActionTypes.SEND_CAMERA_PTZ_CONTROL_REQUEST, requestIPCameraPTZControl);
}

function* watchFetchIPCameraEventsRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_EVENTS_REQUEST, requestIPCameraEvents);
}

function* watchEditIPCameraRequest() {
  yield takeLatest(ActionTypes.EDIT_IP_CAMERA_REQUEST, requestEditIPCamera);
}

function* watchRemoveIPCameraRequest() {
  yield takeLatest(ActionTypes.REMOVE_IP_CAMERA_REQUEST, requestRemoveIPCamera);
}

function* watchFetchIPCameraPTZPresetsRequest() {
  yield takeLatest(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST, requestFetchIPCameraPTZPresets);
}

function* watchCreateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestCreateIPCameraPTZPreset);
}

function* watchUpdateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestUpdateIPCameraPTZPreset);
}

function* watchDeleteIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_REQUEST, requestDeleteIPCameraPTZPreset);
}

function* watchActivateIPCameraPTZPresetRequest() {
  yield takeLatest(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST, requestActivatePTZRequest);
}

// ==============================
// EXPORT
// ==============================
export default function* ipCamerasSaga() {
  yield all([
    fork(watchIPCamerasListBySiteRequest),
    fork(watchIPCameraByIdRequest),
    fork(watchTakeIPCameraPreviewRequest),
    fork(watchFetchIPCameraStateRequest),
    fork(watchFetchIPCameraCapabilitiesRequest),
    fork(watchFetchIPCameraPhotosRequest),
    fork(watchTakeIPCameraPhotoRequest),
    fork(watchIPCameraPTZControlRequest),
    fork(watchFetchIPCameraEventsRequest),
    fork(watchEditIPCameraRequest),
    fork(watchRemoveIPCameraRequest),
    fork(watchFetchIPCameraPTZPresetsRequest),
    fork(watchCreateIPCameraPTZPresetRequest),
    fork(watchUpdateIPCameraPTZPresetRequest),
    fork(watchDeleteIPCameraPTZPresetRequest),
    fork(watchActivateIPCameraPTZPresetRequest)
  ]);
}